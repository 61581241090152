import { writable } from 'svelte/store';

export const artificialScroll = writable(false);

export const setArtificialScroll = () => {
	artificialScroll.set(true);
	setTimeout(() => {
		artificialScroll.set(false);
	}, 1000);
};
