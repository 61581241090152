import { setArtificialScroll } from '$lib/stores/artificalScroll';
import countries from '$lib/data/countries.json';

export const scrollToElement = (element, location) => {
	setArtificialScroll();
	element.scrollIntoView({
		behavior: 'smooth',
		block: location
	});
};

export const capitalise = (str) => {
	const words = str.split(' ');
	const capitalizedWords = words.map((word) => {
		const firstLetter = word.charAt(0).toUpperCase();
		const restOfWord = word.slice(1);
		return firstLetter + restOfWord;
	});
	return capitalizedWords.join(' ');
};

export const capitaliseWords = (str) => {
	return str.replace(/\w\S*/g, (txt) => {
		return capitalise(txt);
	});
};

export const removeCircularReferences = (obj) => {
	const seen = new WeakSet();

	return JSON.parse(
		JSON.stringify(obj, (key, value) => {
			if (typeof value === 'object' && value !== null) {
				if (seen.has(value)) {
					return; // Skip if already seen
				}
				seen.add(value);
			}
			return value;
		})
	);
};

export const revertSlug = (slug) => {
	return slug
		.split('-')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

export const createSlug = (str) => {
	return str.toLowerCase().replace(/\s+/g, '-');
};

export const generateUUID = () => {
	let d = new Date().getTime();
	if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
		d += performance.now();
	}
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
};

export const calculateDelivery = (country) => {
	const { Europe, ROW } = countries;

	country = capitalise(country.trim());

	if (country === 'GB' || country === 'United Kingdom') {
		return 0;
	} else if (country in Europe) {
		return 15;
	} else if (country in ROW) {
		return 35;
	}

	const countryCode = Object.entries(Europe).find(([, value]) => value === country);
	if (countryCode) {
		return 15;
	}

	const rowCountryCode = Object.entries(ROW).find(([, value]) => value === country);
	if (rowCountryCode) {
		return 35;
	}

	return null;
};

export const getCountryFromCode = (code) => {
	const { FREE, Europe, ROW } = countries;

	if (code in FREE) {
		return FREE[code];
	} else if (code in Europe) {
		return Europe[code];
	} else if (code in ROW) {
		return ROW[code];
	} else {
		return null;
	}
};

export const searchCountry = (searchInput) => {
	const query = capitalise(searchInput.trim().toLowerCase());
	const countriesList = Object.values(countries.Europe)
		.concat(Object.values(countries.ROW))
		.concat(Object.values(countries.FREE));

	return countriesList.filter((country) => country.includes(query));
};

export const isValidCountry = (country) => {
	const query = capitalise(country.trim().toLowerCase());
	const countriesList = Object.values(countries.Europe)
		.concat(Object.values(countries.ROW))
		.concat(Object.values(countries.FREE));

	return countriesList.includes(query);
};

export const getCountryCode = (countryName) => {
	const { FREE, Europe, ROW } = countries;

	if (FREE.GB === countryName) {
		return 'GB';
	}

	for (const countryCode in countries.Europe) {
		if (Europe[countryCode] === countryName) {
			return countryCode;
		}
	}

	for (const countryCode in countries.ROW) {
		if (ROW[countryCode] === countryName) {
			return countryCode;
		}
	}

	return null;
};

export const serializeSchema = (schema) => {
	return `<script type="application/ld+json">${JSON.stringify(schema)}</script>`;
};
